import { createFeatureSelector, createSelector } from '@ngrx/store';
import { objectSelector, Orders, OrdersState } from '@assist/shared/data';

export const orders = createFeatureSelector<OrdersState, Orders>('orders');

export const packedOrders = objectSelector(orders, (s1) => ({
  data: s1.data,
  loading: s1.loading,
}));

export const ordersDataLoading = createSelector(orders, (s1) => s1);

export const orderSettings = objectSelector(orders, (_orders) => ({
  from: _orders.from,
  to: _orders.to,
  resourceNames: _orders.resourcesNames,
  services: _orders.services,
  showEventOrders: _orders.showEventOrders,
  showResourceOrders: _orders.showResourceOrders,
  take: _orders.take,
  skip: _orders.skip,
}));
