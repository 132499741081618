import { AssistOrderGridItem } from '@assist/shared/data';
import { createAction, props } from '@ngrx/store';

export const loadOrders = createAction(
  '[Orders] Load',
  props<{
    from: Date;
    to: Date;
    resourcesNames: string[];
    showEventOrders: boolean;
    showResourceOrders: boolean;
    services: any[];
    take: number;
    skip: number;
  }>(),
);
export const loadMoreOrders = createAction('[Orders] LoadMore');
export const setOrdersFilter = createAction(
  '[Orders] SetFilter',
  props<{
    from: Date;
    to: Date;
    resourcesNames: string[];
    services: any[];
    showEventOrders: boolean;
    showResourceOrders: boolean;
  }>(),
);
export const refreshOrders = createAction('[Orders] Refresh');

class AssistOrderGridItemBM {}

export const loadOrdersSuccess = createAction(
  '[Orders] Load Success',
  props<{ orders: AssistOrderGridItem[]; take: number; skip: number }>(),
);
